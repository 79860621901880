
import { defineComponent, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";

import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const token = route.query.token;
    const isNew = route.query.new;
    const router = useRouter();
    const store = useStore();

    const i18n = useI18n();
    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "/media/flags/united-states.svg",
        name: "English"
      },
      es: {
        flag: "/media/flags/spain.svg",
        name: "Spanish"
      },
      de: {
        flag: "/media/flags/germany.svg",
        name: "German"
      },
      ja: {
        flag: "/media/flags/japan.svg",
        name: "Japanese"
      },
      fr: {
        flag: "/media/flags/france.svg",
        name: "French"
      }
    };

    const setLang = lang => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = lang => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    const submitButton = ref<HTMLElement | null>(null);

    const saveNewPassword = values => {
      store.dispatch(Actions.LOGOUT);

      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/users/update-password`;
      const dataBody = {
        token: token,
        password: values.password
      };

      if (values.name) dataBody["name"] = values.name;
      if (values.lastName) dataBody["lastName"] = values.surname;

      setTimeout(() => {
        fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(dataBody)
        })
          .then(res => res.json())
          .then(res => {
            store.dispatch(Actions.SETUP_USER, res.result);

            router.push({ name: "dashboard" });
          })
          .catch(err => {
            //
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 1000);
    };

    const user = {
      name: route.query.name,
      lastName: route.query.lastName
      //email: route.query.email
    };

    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const ChangePassword = Yup.object().shape({
      password: Yup.string()
        .minUppercase(
          1,
          translate("password") + " " + translate("minUppercase")
        )
        .minNumbers(1, translate("password") + " " + translate("minNumbers"))
        .minSymbols(1, translate("password") + " " + translate("minSymbols"))
        .min(8, translate("password") + " " + translate("min"))
        .required(translate("password") + " " + translate("isRequired")),
      passwordConfirmation: Yup.string()
        .required(translate("passwordConfirm") + " " + translate("isRequired"))
        .oneOf([Yup.ref("password"), null], translate("PasswordsMustMatchYup"))
    });

    const registration = Yup.object().shape({
      name: Yup.string().required(
        translate("name") + " " + translate("isRequired")
      ),
      surname: Yup.string().required(
        translate("lastName") + " " + translate("isRequired")
      ),
      toc: Yup.bool().required(
        translate("Accept") + " " + translate("isRequired")
      ),
      //email: Yup.string()
      //.min(4)
      //.required(translate("email") + " " + translate("isRequired"))
      //.email(translate("email") + " " + translate("validEmail")),
      password: Yup.string()
        .minUppercase(
          1,
          translate("password") + " " + translate("minUppercase")
        )
        .minNumbers(1, translate("password") + " " + translate("minNumbers"))
        .minSymbols(1, translate("password") + " " + translate("minSymbols"))
        .min(8, translate("password") + " " + translate("min"))
        .required(translate("password") + " " + translate("isRequired")),
      cpassword: Yup.string()
        .required(translate("passwordConfirm") + " " + translate("isRequired"))
        .oneOf([Yup.ref("password"), null], translate("PasswordsMustMatchYup"))
    });

    return {
      translate,
      token,
      saveNewPassword,
      ChangePassword,
      isNew,
      user,
      registration,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
      submitButton
    };
  }
});
